import React, { useState, useEffect } from 'react';
import FlyingDollar from './FlyingDollar';
import PelosiImage from './NancyPelosi';

const BackgroundAnimation = ({ dollarsEnabled }) => {
    const [dollars, setDollars] = useState([]);

    useEffect(() => {
        let intervalId;

        if (dollarsEnabled) {
            intervalId = setInterval(() => {
                const key = Date.now();
                setDollars((prevDollars) => [
                    ...prevDollars,
                    <FlyingDollar key={key} onRemove={() => removeDollar(key)} />,
                ]);
            }, 300); // Adjust the interval for how often new dollars are added
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [dollarsEnabled]);

    const removeDollar = (key) => {
        setDollars((prevDollars) => prevDollars.filter((dollar) => dollar.key !== String(key)));
    };

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1, // Ensure this is behind all other elements
                pointerEvents: 'none', // Prevent interaction with background
            }}
        >
            <PelosiImage dollarsEnabled={dollarsEnabled} /> {/* Pass the dollarsEnabled prop */}
            {dollars}
        </div>
    );
};

export default BackgroundAnimation;
