import React from 'react';

const SubscriptionAgreement = () => {
  return (
    <div className="subscription-agreement">
      <h1>Subscription Agreement</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>1. Introduction</h2>
      <p>
        This Subscription Agreement ("Agreement") governs your purchase and use of subscription-based services offered by CapitolGains.io ("we", "us", or "our"). 
        By subscribing to our services, you agree to comply with and be bound by this Agreement.
      </p>

      <h2>2. Subscription Services</h2>
      <p>
        We offer various subscription plans, which provide access to certain features and content on CapitolGains.io. Details of the available plans, 
        including pricing and features, are provided on our website. We reserve the right to modify our subscription offerings at any time.
      </p>

      <h2>3. Subscription Term and Renewal</h2>
      <p>
        Your subscription will begin when you complete the subscription process and make the initial payment. Subscriptions are automatically renewed at the 
        end of each term unless you cancel your subscription prior to the renewal date. You authorize us to charge your payment method for the applicable 
        subscription fees for each renewal term.
      </p>

      <h2>4. Billing and Payment</h2>
      <p>
        All subscription fees are billed in advance and are non-refundable, except as provided in our Refund Policy. You agree to provide accurate and complete 
        payment information and to update your information as necessary. If we are unable to process your payment, we may suspend or cancel your subscription.
      </p>

      <h2>5. Cancellation and Termination</h2>
      <p>
        You may cancel your subscription at any time through your account settings or by contacting our customer support team. Your subscription will remain active 
        until the end of the current billing period. We reserve the right to terminate your subscription for any violation of this Agreement or for any other reason 
        at our sole discretion.
      </p>

      <h2>6. Changes to Subscription Plans</h2>
      <p>
        We may change our subscription plans, including pricing and features, from time to time. Any changes will apply to new subscribers or upon renewal of your 
        subscription. We will provide notice of any material changes to your subscription plan before they take effect.
      </p>

      <h2>7. Use of Services</h2>
      <p>
        Your subscription is for your personal use only and may not be shared, transferred, or resold. You agree to use our services in accordance with all applicable 
        laws and regulations and to comply with our Terms of Service and Acceptable Use Policy.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, CapitolGains.io, its affiliates, and their respective officers, directors, employees, and agents will not be liable for 
        any indirect, incidental, special, consequential, or punitive damages arising from or related to your subscription or use of our services.
      </p>

      <h2>9. Changes to This Agreement</h2>
      <p>
        We may update this Subscription Agreement from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our 
        services after changes are made signifies your acceptance of the revised Agreement.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about this Subscription Agreement, please contact us at [contact email or form link].
      </p>
    </div>
  );
};

export default SubscriptionAgreement;
