import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Grid, FormControlLabel, Switch } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PricingIcon from '@mui/icons-material/AttachMoney';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import logo from '../assets/capitol_gains_logo_250x250.png';

function Navbar({ user, signOut, toggleDollars, dollarsEnabled }) {
    const [settingsEl, setSettingsEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const location = useLocation(); // Use this hook to get the current route

    const handleSettingsOpen = (event) => {
        setSettingsEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setSettingsEl(null);
    };

    const handleSignOutClick = () => {
        handleSettingsClose();
        signOut();
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    return (
        <AppBar
            position="sticky"
            sx={{
                background: 'linear-gradient(90deg, rgba(33,150,243,1) 0%, rgba(30,87,153,1) 50%, rgba(33,150,243,1) 100%)',
                top: 0,
                zIndex: theme.zIndex.drawer + 1,
            }}
        >
            <Toolbar>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={8} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link
                            to="/"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: 'inherit',
                            }}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            onClick={() => setHovered(false)}
                        >
                            <motion.div
                                animate={{
                                    scale: hovered ? 1.02 : 1,
                                    filter: hovered ? 'drop-shadow(0 6px 10px rgba(0, 0, 0, 0.4))' : 'none',
                                }}
                                transition={{ type: 'spring', stiffness: 1000 }}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <img
                                    src={logo}
                                    alt="CapitolGains Logo"
                                    style={{ height: '50px' }}
                                />
                                <Typography
                                    variant="h6"
                                    component="h6"
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        transform: 'scaleY(1.5) scaleX(0.75)',
                                        display: 'inline-block',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    CAPITOL GAINS
                                </Typography>
                            </motion.div>
                        </Link>
                    </Grid>

                    {!isMobile && (
                        <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button component={Link} to="/recent-trades" color="inherit" sx={{ fontWeight: 'bold' }}>
                                    Congress Trades
                                </Button>
                                <Button component={Link} to="/stocks" color="inherit" sx={{ fontWeight: 'bold' }}>
                                    Stock Rankings
                                </Button>
                                <Button component={Link} to="/leaderboard" color="inherit" sx={{ fontWeight: 'bold' }}>
                                    Congress Leaderboard
                                </Button>
                                <Button component={Link} to="/pricing" color="inherit" sx={{ fontWeight: 'bold' }}>
                                    Pricing
                                </Button>
                                <Button component={Link} to="/about" color="inherit" sx={{ fontWeight: 'bold' }}>
                                    About Us
                                </Button>
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={4} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {isMobile ? (
                            <>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleDrawer(true)}
                                    sx={{ mr: 1 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    anchor="right"
                                    open={drawerOpen}
                                    onClose={toggleDrawer(false)}
                                >
                                    <Box
                                        sx={{ width: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
                                    >
                                        <List>
                                            <ListItem>
                                                <IconButton edge="start" color="inherit" onClick={toggleDrawer(false)}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </ListItem>
                                            <Divider />
                                            <ListItem button component={Link} to="/stocks" onClick={toggleDrawer(false)}>
                                                <ListItemIcon><HomeIcon /></ListItemIcon>
                                                <ListItemText primary="Stock Rankings" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/leaderboard" onClick={toggleDrawer(false)}>
                                                <ListItemIcon><LeaderboardIcon /></ListItemIcon>
                                                <ListItemText primary="Congress Leaderboard" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/recent-trades" onClick={toggleDrawer(false)}>
                                                <ListItemIcon><TrendingUpIcon /></ListItemIcon>
                                                <ListItemText primary="Trading Activity" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/pricing" onClick={toggleDrawer(false)}>
                                                <ListItemIcon><PricingIcon /></ListItemIcon>
                                                <ListItemText primary="Pricing" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/about" onClick={toggleDrawer(false)}>
                                                <ListItemIcon><InfoIcon /></ListItemIcon>
                                                <ListItemText primary="About Us" />
                                            </ListItem>
                                            {user && (
                                                <ListItem button component={Link} to="/account" onClick={toggleDrawer(false)}>
                                                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                                    <ListItemText primary="Account" />
                                                </ListItem>
                                            )}
                                        </List>
                                        <Divider />
                                        {user && (
                                            <ListItem button onClick={handleSignOutClick} sx={{ mt: 'auto' }}>
                                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                                <ListItemText primary="Sign Out" />
                                            </ListItem>
                                        )}
                                    </Box>
                                </Drawer>
                            </>
                        ) : (
                            <>
                                {/* Conditionally render the "Make it Rain" toggle only on the home page */}
                                {location.pathname === '/' && (
                                    <FormControlLabel
                                        control={<Switch checked={dollarsEnabled} onChange={toggleDollars} color="default" />}
                                        label="Make it Rain"
                                        labelPlacement="start"
                                        sx={{ color: 'white' }}
                                    />
                                )}
                                {user ? (
                                    <>
                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            aria-label="settings"
                                            onClick={handleSettingsOpen}
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={settingsEl}
                                            open={Boolean(settingsEl)}
                                            onClose={handleSettingsClose}
                                        >
                                            <MenuItem onClick={handleSettingsClose} component={Link} to="/account">
                                                Account
                                            </MenuItem>
                                            <MenuItem onClick={handleSignOutClick}>
                                                Sign Out
                                            </MenuItem>
                                        </Menu>
                                    </>
                                ) : (
                                    <Button component={Link} to="/sign-in" color="inherit" sx={{ fontWeight: 'bold' }}>
                                        Sign In
                                    </Button>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
