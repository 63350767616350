import React from 'react';

const AccessibilityStatement = () => {
  return (
    <div className="accessibility-statement">
      <h1>Accessibility Statement</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>1. Our Commitment to Accessibility</h2>
      <p>
        CapitolGains.io is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and 
        applying the relevant accessibility standards to enhance the accessibility of our website and services.
      </p>

      <h2>2. Measures to Support Accessibility</h2>
      <p>
        We take the following measures to ensure accessibility of CapitolGains.io:
      </p>
      <ul>
        <li>Integrating accessibility as part of our development process.</li>
        <li>Conducting regular accessibility audits and testing.</li>
        <li>Training our staff on accessibility best practices.</li>
        <li>Utilizing automated tools and manual testing to identify and address accessibility issues.</li>
      </ul>

      <h2>3. Conformance Status</h2>
      <p>
        The Web Content Accessibility Guidelines (WCAG) define requirements for designers and developers to improve accessibility for people with disabilities. 
        CapitolGains.io aims to conform to WCAG 2.1, Level AA.
      </p>

      <h2>4. Feedback</h2>
      <p>
        We welcome your feedback on the accessibility of CapitolGains.io. If you encounter any accessibility barriers or have suggestions on how we can improve accessibility, 
        please contact us:
      </p>
      <p>Email: <a href="mailto:[contact email]">[contact email]</a></p>
      <p>Phone: [Your Phone Number]</p>
      <p>Mailing Address: [Your Street Address, City, State, Zip Code, Country]</p>

      <h2>5. Accessibility Features</h2>
      <p>
        Our website includes the following accessibility features:
      </p>
      <ul>
        <li>Text alternatives for non-text content.</li>
        <li>Keyboard-accessible navigation.</li>
        <li>Consistent and clear layout and design.</li>
        <li>Accessible forms and interactive elements.</li>
      </ul>

      <h2>6. Limitations and Alternatives</h2>
      <p>
        Despite our best efforts to ensure accessibility, some content may not yet be fully adapted to the strictest accessibility standards. If you need alternative formats or 
        encounter an issue, please contact us for assistance.
      </p>

      <h2>7. Ongoing Efforts</h2>
      <p>
        We are committed to continuously improving the accessibility of our website and services. We regularly review our accessibility practices and policies to ensure they 
        meet or exceed the latest standards.
      </p>

      <h2>8. Changes to This Accessibility Statement</h2>
      <p>
        We may update this Accessibility Statement from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website 
        after changes are made signifies your acceptance of the revised statement.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about our Accessibility Statement or need assistance accessing any part of our website, please contact us at [contact email].
      </p>
    </div>
  );
};

export default AccessibilityStatement;
