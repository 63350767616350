import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="refund-policy">
      <h1>Refund Policy</h1>

      <p>Last Updated: [Date]</p>

      <h2>1. Overview</h2>
      <p>
        At CapitolGains.io, we strive to provide the best possible experience for our users. This Refund Policy outlines the terms under which we offer refunds for 
        our subscription-based services.
      </p>

      <h2>2. Eligibility for Refunds</h2>
      <p>
        Refunds are only available for users who have purchased a subscription plan and request a refund within the first [number of days, e.g., 14 days] of their 
        initial purchase. To be eligible for a refund, the user must demonstrate that they have experienced a significant issue with our service.
      </p>

      <h2>3. Non-Refundable Items</h2>
      <p>
        Payments for the following are non-refundable:
      </p>
      <ul>
        <li>Partial months of service</li>
        <li>Upgrades or downgrades in subscription plan</li>
        <li>One-time fees, such as setup fees or add-ons</li>
      </ul>

      <h2>4. Requesting a Refund</h2>
      <p>
        To request a refund, please contact our customer support team at [support email or form link] within the eligible timeframe. Please include your account 
        details and the reason for your refund request. We will review your request and respond within [number of days, e.g., 7 business days].
      </p>

      <h2>5. Processing Refunds</h2>
      <p>
        Once a refund is approved, it will be processed to the original payment method within [number of days, e.g., 10 business days]. Please note that it may take 
        additional time for your bank or credit card issuer to post the refund to your account.
      </p>

      <h2>6. Changes to This Policy</h2>
      <p>
        We reserve the right to modify this Refund Policy at any time. Any changes will be posted on this page with an updated effective date. Your continued use 
        of our services after changes are made signifies your acceptance of the revised policy.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about our Refund Policy, please contact us at [contact email or form link].
      </p>
    </div>
  );
};

export default RefundPolicy;
