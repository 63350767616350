const awsmobile = {
    aws_project_region: 'us-west-1',
    aws_cognito_region: 'us-west-1',
    aws_user_pools_id: 'us-west-1_1wTtAvYgz',
    aws_user_pools_web_client_id: '556f0pf9irlonio1544ju0qkag',
    aws_cognito_identity_pool_id: 'us-west-1:514beb80-e13a-4bfb-8863-dacf4ffcb759',
    oauth: {
        domain: 'capitolgains.auth.us-west-1.amazoncognito.com',
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
        responseType: 'code',
    },
};

export default awsmobile;
