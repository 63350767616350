import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const StockRankingsInfo = () => {
  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      <Box mb={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          How Stock Rankings Are Determined on CapitolGains.io
        </Typography>
        <Typography variant="body1" paragraph>
          At CapitolGains.io, our stock rankings are designed to give you insight into which stocks are currently favored or disfavored based on trading activities by U.S. Congress members. We use a comprehensive scoring system that considers various factors related to recent trades. Here's how the rankings are determined:
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          What Makes a Stock Rank Higher:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Higher Purchase Volume:</strong> When a stock is bought in large quantities, it positively impacts its ranking.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Higher Purchase Options Sentiment:</strong> This score reflects the level of optimism or bullishness associated with options trades for a stock. For example, if traders are buying risky options that bet on the stock's price increasing (like Out-Of-The-Money call options), the stock's ranking may increase.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>More Frequent Purchases:</strong> The more often a stock is bought, the higher its score will be.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Greater Number of Individual Buyers:</strong> A stock purchased by many different people is ranked higher, suggesting broader interest and confidence.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Recent Purchases:</strong> Stocks that were bought recently are given more weight in the rankings, as they reflect current trends.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>High Buyer Credibility:</strong> If the buyer has a strong historical track record of making good trades, the stock's purchase is considered more likely to be successful, which increases its score.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          What Makes a Stock Rank Lower:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Higher Sale Volume:</strong> Large quantities of a stock being sold will negatively impact its ranking.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Higher Sale Options Sentiment:</strong> This score reflects the level of pessimism or bearishness associated with options trades for a stock. For example, if traders are selling options that bet on the stock's price decreasing (like Out-Of-The-Money put options), the stock's ranking may decrease.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>More Frequent Sales:</strong> Frequent selling activity lowers a stock’s score, indicating reduced confidence.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Greater Number of Individual Sellers:</strong> A stock sold by many different people suggests a lack of confidence, leading to a lower ranking.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Recent Sales:</strong> Stocks that were sold recently are given more weight, decreasing their ranking as they reflect current trends in selling.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>High Sale Credibility:</strong> If the seller has a strong historical track record of making good trades, the stock's sale is considered more likely to precede a decline in value, which lowers its score.
            </Typography>
          </li>
        </ul>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Summary
        </Typography>
        <Typography variant="body1" paragraph>
          The overall ranking of a stock is determined by balancing the factors that increase its score (like strong buying activity and buyer confidence) against those that decrease it (like significant selling activity and sale sentiment). This approach ensures that the rankings reflect both positive and negative sentiments of Congress members' trading activities, giving you a clear and transparent view of the market dynamics at play.
        </Typography>
        <Typography variant="body1" paragraph>
          This system is designed to be straightforward, helping you understand why certain stocks are ranked higher or lower based on the latest data from U.S. Congress members.
        </Typography>
      </Box>
    </Container>
  );
};

export default StockRankingsInfo;
