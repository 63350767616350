import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import DollarIcon from './DollarIcon';

const getRandomPosition = () => ({
    x: Math.random() * window.innerWidth,
    y: -100, // Start slightly above the screen
});

const getRandomRotationSpeed = () => ({
    y: 1 + Math.random() * 4, // Random speed between 1 and 5 seconds for Y rotation
    x: 2 + Math.random() * 3, // Random speed between 2 and 5 seconds for X rotation
});

const FlyingDollar = ({ onRemove }) => {
    const controls = useAnimation();
    const [rotationSpeed, setRotationSpeed] = useState(getRandomRotationSpeed());

    useEffect(() => {
        const animate = async () => {
            await controls.start({
                x: getRandomPosition().x,
                y: window.innerHeight + 100, // Move dollar off-screen to the bottom
                transition: {
                    duration: 5 + Math.random() * 2, // Random duration between 5 and 7 seconds
                    ease: "linear",
                },
            });

            // Notify parent component to remove this dollar
            if (onRemove) {
                onRemove();
            }
        };

        animate();

        // Periodically update rotation speeds
        const intervalId = setInterval(() => {
            setRotationSpeed(getRandomRotationSpeed());
        }, 5000 + Math.random() * 5000); // Change speed every 5-10 seconds

        return () => clearInterval(intervalId);
    }, [controls, onRemove]);

    return (
        <motion.div
            animate={controls}
            style={{
                position: 'absolute',
                width: '50px',
                height: '30px',
                perspective: '1000px',
                userSelect: 'none',
            }}
        >
            <motion.div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    transformStyle: 'preserve-3d', // Add this line
                    userSelect: 'none',
                }}
                animate={{
                    rotateY: [0, 360],
                    rotateX: [-30, 0, 30, 0],
                }}
                transition={{
                    rotateY: {
                        duration: rotationSpeed.y,
                        repeat: Infinity,
                        ease: "linear",
                    },
                    rotateX: {
                        duration: rotationSpeed.x,
                        repeat: Infinity,
                        ease: "easeInOut",
                    },
                }}
            >
                {/* Shadow element (unchanged) */}
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        filter: 'blur(8px)',
                        transform: 'scale(1.1)',
                        zIndex: -1,
                        userSelect: 'none',
                    }}
                />
                {/* Front of the dollar bill */}
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backfaceVisibility: 'hidden',
                        userSelect: 'none',
                    }}
                >
                    <DollarIcon isBack={false} />
                </div>
                {/* Back of the dollar bill */}
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backfaceVisibility: 'hidden',
                        transform: 'rotateY(180deg)',
                        userSelect: 'none',
                    }}
                >
                    <DollarIcon isBack={true} />
                </div>
            </motion.div>
        </motion.div>
    );
};

export default FlyingDollar;