import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  return (
    <Container maxWidth="md" sx={{ paddingTop: 8, paddingBottom: 8 }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Frequently Asked Questions
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Does Capitol Gains make trades for me?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, Capitol Gains is not a trading platform. Our platform provides tools and insights for monitoring the stock trades of U.S. Congress members, offering transparency and analysis. We do not execute trades on behalf of users.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Is Capitol Gains a financial advisor?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, Capitol Gains is not a licensed financial advisor. We provide information and analysis based on public data but do not offer personalized financial advice or recommendations for purchasing or selling any assets.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Where does Capitol Gains get Congress stock trades from?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Capitol Gains sources up-to-date stock trades and financial disclosures directly from the Senate Public Financial Disclosure Database and the House of Representatives Financial Disclosure Reports websites. Our data is refreshed daily to ensure accuracy and timeliness.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Additional FAQs */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>How often is the data on Capitol Gains updated?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The data on Capitol Gains is updated daily to reflect the most recent filings from U.S. Congress members. We strive to provide the most current information available to our users.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Do I need a subscription to use Capitol Gains?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Capitol Gains offers both free and paid subscription tiers. The free tier provides access to historical data with a 1-month delay, while the paid subscription offers real-time data, advanced analytics, and more comprehensive insights.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>How can I use the information from Capitol Gains?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The data and tools provided by Capitol Gains are designed to help users monitor the stock trading activities of U.S. Congress members and identify potential trends and insights. Users can utilize this information to stay informed about financial activities within the government, but any investment decisions should be made independently and with caution.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7a-content" id="panel7a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Is my personal information safe with Capitol Gains?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, Capitol Gains takes your privacy and security seriously. We employ industry-standard encryption and security measures to protect your personal information. For more details, please refer to our Privacy Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8a-content" id="panel8a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Can I cancel my subscription at any time?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can cancel your subscription at any time. If you decide to cancel, your access to premium features will continue until the end of your current billing cycle. For more information, please see our Refund Policy.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9a-content" id="panel9a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Does Capitol Gains provide investment recommendations?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, Capitol Gains does not provide investment recommendations. Our platform is designed to provide transparency into the stock trades of U.S. Congress members and does not offer personalized investment advice. Users should conduct their own research or consult with a professional advisor before making any investment decisions.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10a-content" id="panel10a-header">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>How can I contact Capitol Gains support?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can contact Capitol Gains support through our Contact Information page. We are here to assist with any questions or issues you may have regarding your account or our platform.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQ;
