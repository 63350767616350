import React from 'react';

const ContactInformation = () => {
  return (
    <div className="contact-information">
      <h1>Contact Information</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>How to Contact Us</h2>
      <p>
        If you have any questions, comments, or concerns about CapitolGains.io, our services, or any of our policies, please feel free to reach out to us. 
        We value your feedback and are here to help with any inquiries you may have.
      </p>

      <h2>Email</h2>
      <p>
        You can email us at: <a href="mailto:[contact email]">[contact email]</a>
      </p>

      <h2>Mailing Address</h2>
      <p>
        CapitolGains.io<br />
        [Your Street Address]<br />
        [City, State, Zip Code]<br />
        [Country]
      </p>

      <h2>Customer Support</h2>
      <p>
        For customer support inquiries, please email us at: <a href="mailto:[support email]">[support email]</a>. We strive to respond to all support 
        inquiries within [number of days, e.g., 2 business days].
      </p>

      <h2>Social Media</h2>
      <p>
        Follow us on social media for updates and news:
      </p>
      <ul>
        <li><a href="[Your Facebook URL]" target="_blank" rel="noopener noreferrer">Facebook</a></li>
        <li><a href="[Your Twitter URL]" target="_blank" rel="noopener noreferrer">Twitter</a></li>
        <li><a href="[Your LinkedIn URL]" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
      </ul>

      <h2>Business Hours</h2>
      <p>
        Our business hours are Monday to Friday, [Time Range, e.g., 9 AM - 5 PM]. We are closed on weekends and public holidays.
      </p>

      <h2>Feedback</h2>
      <p>
        We welcome your feedback on how we can improve our services. Please email any suggestions or comments to: <a href="mailto:[feedback email]">[feedback email]</a>
      </p>
    </div>
  );
};

export default ContactInformation;
