import React from 'react';

const ComplianceDisclosures = () => {
  return (
    <div className="compliance-disclosures">
      <h1>Compliance Disclosures</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>1. Introduction</h2>
      <p>
        At CapitolGains.io, we are committed to transparency and compliance with all applicable laws and regulations. This page outlines our compliance disclosures 
        to help you understand our practices and the regulations that govern our services.
      </p>

      <h2>2. Data Privacy and Protection</h2>
      <p>
        We comply with applicable data protection laws, including the General Data Protection Regulation (GDPR) for users in the European Union and the California 
        Consumer Privacy Act (CCPA) for users in California. For more information on how we handle your personal data, please refer to our <a href="/privacy-policy">Privacy Policy</a>.
      </p>

      <h2>3. Financial Disclosures</h2>
      <p>
        CapitolGains.io provides financial data and analysis for informational purposes only. We do not offer investment advice or recommendations, and our services 
        should not be construed as such. Please see our <a href="/disclaimer">Disclaimer</a> for more information.
      </p>

      <h2>4. Accessibility Compliance</h2>
      <p>
        We are committed to making our website accessible to all users, including those with disabilities. We follow the Web Content Accessibility Guidelines (WCAG) 
        2.1 to ensure our site is accessible. If you encounter any accessibility issues, please contact us at [contact email].
      </p>

      <h2>5. Copyright and Intellectual Property</h2>
      <p>
        All content on CapitolGains.io is protected by copyright and other intellectual property laws. Unauthorized use of our content is prohibited. For more information, 
        please refer to our <a href="/terms-of-service">Terms of Service</a>.
      </p>

      <h2>6. Third-Party Disclosures</h2>
      <p>
        Our website may include links to third-party websites or services. We are not responsible for the content or practices of these third parties. We encourage you to 
        review the privacy policies and terms of use of any third-party sites you visit.
      </p>

      <h2>7. Changes to This Page</h2>
      <p>
        We may update these compliance disclosures from time to time to reflect changes in our practices or applicable laws. Any updates will be posted on this page with an 
        updated effective date. Your continued use of our services after changes are made signifies your acceptance of the revised disclosures.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about our compliance disclosures, please contact us at [contact email].
      </p>
    </div>
  );
};

export default ComplianceDisclosures;
