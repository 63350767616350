import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Box,
    Stack,
    Paper,
    Divider
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';  // Import Circle icon for the party dot
import { fetchAuthSession } from 'aws-amplify/auth';

function TradingLeaderboard() {
    const [leaders, setLeaders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const session = await fetchAuthSession();
                if (session && session.tokens && session.tokens.accessToken) {
                    const accessToken = session.tokens.accessToken.toString();
                    fetch('https://api.capitolgains.io/user/congress', {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setLeaders(data.trader_stats || []);
                            setLoading(false);
                        })
                        .catch((error) => {
                            setError(error);
                            setLoading(false);
                        });
                } else {
                    throw new Error('Session is invalid or missing access token');
                }
            } catch (error) {
                setError(new Error('User is not authenticated or token is missing'));
                setLoading(false);
            }
        };

        fetchSession();
    }, []);

    if (loading) {
        return (
            <Container>
                <Grid container justifyContent="center">
                    <CircularProgress />
                </Grid>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Alert severity="error">Error: {error.message}</Alert>
            </Container>
        );
    }

    const validLeaders = leaders.filter((member) => {
        const bullishTransactions = member.bullish_transactions || {};
        const avgReturns = member.avg_returns || {};
        const stocksReturns = avgReturns.stocks || {};
        const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : null;
        const accuracy = bullishTransactions.accuracy || null;
        const purchaseVolume = bullishTransactions.volume || null;

        return purchaseROI !== null && accuracy !== null && purchaseVolume !== null;
    });

    return (
        <Container>
            <Typography variant="h4" gutterBottom sx={{ color: '#ffffff' }}>
                Congressional Stock Trading Leaderboard
            </Typography>
            <Grid container spacing={3}>
                {validLeaders.length === 0 ? (
                    <Grid item xs={12}>
                        <Alert severity="info">No data available.</Alert>
                    </Grid>
                ) : (
                    validLeaders.map((member, index) => {
                        const bullishTransactions = member.bullish_transactions || {};
                        const avgReturns = member.avg_returns || {};
                        const stocksReturns = avgReturns.stocks || {};
                        const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : null;
                        const purchaseCount = bullishTransactions.count || 0;
                        const accuracy = bullishTransactions.accuracy || null;
                        const purchaseVolume = bullishTransactions.volume || null;

                        const notificationHistory = member.notification_history || {};
                        const notificationFrequency = notificationHistory.notification_frequency || 'N/A';
                        const transactionFrequency = notificationHistory.transaction_frequency || 'N/A';
                        const avgNotificationDelay = notificationHistory.avg_notification_delay || 'N/A';

                        const roiPercent =
                            purchaseROI >= 1
                                ? ((purchaseROI - 1) * 100).toFixed(2)
                                : ((purchaseROI - 1) * 100).toFixed(2);

                        const formattedVolume = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        }).format(purchaseVolume);

                        const dynamicRank = index + 1;

                        const roiStyle = {
                            color: purchaseROI >= 1 ? '#76ff03' : '#ff1744',  // Green for positive ROI, red for negative ROI
                        };

                        const formattedRoiPercent = purchaseROI >= 1 ? `+${roiPercent}%` : `${roiPercent}%`;

                        const memberDetails = member.member_details || {};
                        const memberImage = memberDetails.image || 'https://via.placeholder.com/150';
                        const memberWebsite = memberDetails.website || '#';
                        const memberParty = memberDetails.party || 'Unknown Party';
                        const memberState = memberDetails.state || 'Unknown State';

                        // Determine the color of the dot based on the party
                        const partyColor = memberParty === 'Democratic' ? '#1976d2' : memberParty === 'Republican' ? '#d32f2f' : '#757575'; 

                        return (
                            <Grid item xs={12} md={6} key={index}>
                                <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden', backgroundColor: '#333333' }}>
                                    {/* Header Section */}
                                    <Box sx={{ padding: 2, backgroundColor: '#424242', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                                <a href={memberWebsite} target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff', textDecoration: 'none' }}>
                                                    {member.name}
                                                </a>
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <CircleIcon sx={{ fontSize: 16, color: partyColor, marginRight: 0.6 }} />
                                                <Typography variant="subtitle2" sx={{ color: '#ffffff' }}>
                                                    {memberParty} - {memberState}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography variant="h4" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                                            #{dynamicRank}
                                        </Typography>
                                    </Box>
                                    <Divider sx={{ borderColor: '#616161' }} />
                                    {/* Main Content Section */}
                                    <Box
                                        sx={{
                                            display: 'flex',  // Flexbox for side-by-side layout
                                            flexDirection: 'row',  // Keep row direction for larger screens
                                            padding: 2,
                                            alignItems: 'flex-start',
                                            backgroundColor: '#333333',
                                        }}
                                    >
                                        {/* Stats Section on the Left */}
                                        <Box sx={{ flex: 2, color: '#bdbdbd' }}>
                                            {/* Single column of stats */}
                                            <Stack spacing={1}>
                                                <Typography variant="body2">
                                                    <strong>Ranking:</strong> #{dynamicRank}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Number of Purchases:</strong> {purchaseCount}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Accuracy:</strong> {(accuracy * 100).toFixed(2)}%
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Average Volume:</strong> {formattedVolume}
                                                </Typography>
                                                <Typography variant="body2" sx={roiStyle}>
                                                    <strong>Average ROI:</strong> {formattedRoiPercent}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Notification Frequency:</strong> {notificationFrequency}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Transaction Frequency:</strong> {transactionFrequency}
                                                </Typography>
                                                <Typography variant="body2">
                                                    <strong>Average Notification Delay:</strong> {avgNotificationDelay}
                                                </Typography>
                                            </Stack>
                                        </Box>
                                        {/* Portrait Image Section on the Right */}
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '150px',  // Set fixed width for consistent image size
                                                height: '200px',  // Set fixed height for consistent image size
                                                overflow: 'hidden',  // Hide overflow to crop images
                                                backgroundColor: '#424242',  // Darker grey background
                                            }}
                                        >
                                            <Box
                                                component="img"
                                                src={memberImage}
                                                alt="Profile Picture"
                                                sx={{
                                                    width: '100%',  // Ensure the image fills the container width
                                                    height: '100%',  // Ensure the image fills the container height
                                                    objectFit: 'cover',  // Crop images to fill the area and maintain aspect ratio
                                                    borderRadius: '8px'
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        );
                    })
                )}
            </Grid>
        </Container>
    );
}

export default TradingLeaderboard;
