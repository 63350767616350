import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p><strong>Last Updated:</strong> [Date]</p>
      
      <h2>1. Introduction</h2>
      <p>Welcome to CapitolGains.io! These Terms of Service ("Terms") govern your use of our website and services ("Service"). By accessing or using CapitolGains.io, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Service.</p>
      
      <h2>2. Acceptance of Terms</h2>
      <p>By using CapitolGains.io, you affirm that you are at least 18 years of age and have the legal capacity to enter into these Terms. If you are using the Service on behalf of an organization, you represent that you have the authority to bind the organization to these Terms.</p>
      
      <h2>3. Modification of Terms</h2>
      <p>CapitolGains.io reserves the right to modify these Terms at any time, with or without prior notice. We will notify users of any significant changes by posting a notice on our website or sending an email. Your continued use of the Service after the changes become effective constitutes your acceptance of the new Terms.</p>
      
      <h2>4. Use of the Service</h2>
      <p>You agree to use CapitolGains.io only for lawful purposes and in accordance with these Terms. You agree not to:</p>
      <ul>
        <li>Violate any applicable federal, state, local, or international law or regulation.</li>
        <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Service, or which, as determined by us, may harm CapitolGains.io or users of the Service.</li>
        <li>Use the Service in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Service.</li>
      </ul>
      
      <h2>5. Account Responsibilities</h2>
      <p>To access certain features of CapitolGains.io, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
      
      <h2>6. Intellectual Property Rights</h2>
      <p>All content on CapitolGains.io, including but not limited to text, graphics, logos, images, and software, is the property of CapitolGains.io or its content suppliers and is protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You may not copy, modify, distribute, or create derivative works based on our content without express permission from us.</p>
      
      <h2>7. User-Generated Content</h2>
      <p>CapitolGains.io may allow users to post content, including comments and other materials. By posting content, you grant CapitolGains.io a non-exclusive, royalty-free, perpetual, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. We reserve the right to remove or modify user content for any reason.</p>
      
      <h2>8. Third-Party Links</h2>
      <p>CapitolGains.io may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that CapitolGains.io shall not be responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
      
      <h2>9. Termination of Service</h2>
      <p>We reserve the right to terminate or suspend your access to all or part of CapitolGains.io, without notice, for any reason, including without limitation, breach of these Terms. Upon termination, your right to use the Service will cease immediately. If you wish to terminate your account, you may discontinue using the Service.</p>
      
      <h2>10. Disclaimer of Warranties</h2>
      <p>CapitolGains.io is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Service for any purpose. Your use of the Service is at your sole risk.</p>
      
      <h2>11. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, CapitolGains.io shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service.</p>
    </div>
  );
};

export default TermsOfService;
