// src/pages/ContactPage.js
import React from 'react';
import { Container } from '@mui/material';
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <ContactForm />
    </Container>
  );
};

export default ContactPage;
