import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton, Button } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Footer = () => {
    return (
        <Box sx={{ bgcolor: 'primary.dark', color: 'white', py: 6, mt: 8 }}>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            CapitolGains.io
                        </Typography>
                        <Typography variant="body2" sx={{ maxWidth: 250 }}>
                            Providing transparency on stock trading activities by U.S. Congress members.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Quick Links
                        </Typography>
                        <Box>
                            <Link href="/" color="inherit" variant="body2" display="block" sx={{ mb: 0.5, '&:hover': { color: 'secondary.main' } }}>Home</Link>
                            <Link href="/about" color="inherit" variant="body2" display="block" sx={{ mb: 0.5, '&:hover': { color: 'secondary.main' } }}>About Us</Link>
                            <Link href="/pricing" color="inherit" variant="body2" display="block" sx={{ mb: 0.5, '&:hover': { color: 'secondary.main' } }}>Pricing</Link>
                            <Link href="/contact" color="inherit" variant="body2" display="block" sx={{ mb: 0.5, '&:hover': { color: 'secondary.main' } }}>Contact</Link>
                            <Link href="/faq" color="inherit" variant="body2" display="block" sx={{ '&:hover': { color: 'secondary.main' } }}>FAQ</Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Follow Us
                        </Typography>
                        <Box>
                            <IconButton href="https://x.com/capitolgains_io" target="_blank" color="inherit" sx={{ '&:hover': { color: 'secondary.main' } }}>
                                <XIcon />
                            </IconButton>
                        </Box>
                        {/* Replace Contact Email Section with a Button */}
                        <Box mt={2}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                href="/contact"
                                endIcon={<ArrowForwardIcon />}
                                sx={{
                                    '&:hover': {
                                        borderColor: 'grey.400',
                                        color: 'grey.400',
                                    },
                                    borderColor: 'grey.400', // Default border color
                                }}
                            >
                                Contact Us
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {/* New row for policies */}
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 10 }}>
                    <Grid item>
                        <Link href="/terms-of-service" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Terms of Service</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/privacy-policy" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Privacy Policy</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/refund-policy" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Refund Policy</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/cookie-policy" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Cookie Policy</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/disclaimer" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Disclaimer</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/subscription-agreement" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Subscription Agreement</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/acceptable-use-policy" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Acceptable Use Policy</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/accessibility-statement" color="inherit" variant="body2" sx={{ mx: 1, color: 'grey.400', '&:hover': { color: 'secondary.main' } }}>Accessibility Statement</Link>
                    </Grid>
                </Grid>

                {/* Reduced margin and padding for smaller vertical spacing */}
                <Box mt={2} pt={1}>
                    <Typography variant="body2" align="center" sx={{ color: 'grey.400' }}>
                        &copy; {new Date().getFullYear()} CapitolGains.io. All rights reserved.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
