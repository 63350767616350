import React from 'react';

const AcceptableUsePolicy = () => {
  return (
    <div className="acceptable-use-policy">
      <h1>Acceptable Use Policy</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>1. Introduction</h2>
      <p>
        This Acceptable Use Policy ("Policy") outlines the rules and guidelines for using the services provided by CapitolGains.io ("we", "us", or "our"). 
        By using our services, you agree to comply with this Policy and any updates that we may make from time to time.
      </p>

      <h2>2. Prohibited Activities</h2>
      <p>
        You may not use our services to engage in any of the following activities:
      </p>
      <ul>
        <li>Any illegal activities or activities that promote illegal behavior.</li>
        <li>Harassing, abusing, or threatening others, or encouraging others to do so.</li>
        <li>Uploading or distributing any malicious software, viruses, or any other harmful code.</li>
        <li>Infringing on the intellectual property rights of others.</li>
        <li>Sending spam or unsolicited messages.</li>
        <li>Engaging in any activities that could disrupt, damage, or impair our services or the networks or systems of others.</li>
        <li>Attempting to gain unauthorized access to any accounts, systems, or networks.</li>
      </ul>

      <h2>3. User Responsibilities</h2>
      <p>
        As a user of our services, you agree to:
      </p>
      <ul>
        <li>Use our services in a manner consistent with all applicable laws and regulations.</li>
        <li>Respect the rights of others, including their privacy and intellectual property rights.</li>
        <li>Provide accurate and complete information when creating an account or using our services.</li>
        <li>Promptly report any security breaches or unauthorized use of your account to us.</li>
      </ul>

      <h2>4. Enforcement and Consequences</h2>
      <p>
        We reserve the right to investigate any violations of this Policy and take appropriate action, including but not limited to suspending or terminating 
        your access to our services, reporting violations to law enforcement, and pursuing legal action.
      </p>

      <h2>5. Monitoring and Reporting</h2>
      <p>
        We may monitor your use of our services to ensure compliance with this Policy. If you become aware of any violations of this Policy, please report them to us 
        at [contact email or form link].
      </p>

      <h2>6. Changes to This Policy</h2>
      <p>
        We may update this Acceptable Use Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our 
        services after changes are made signifies your acceptance of the revised Policy.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about this Acceptable Use Policy, please contact us at [contact email or form link].
      </p>
    </div>
  );
};

export default AcceptableUsePolicy;
