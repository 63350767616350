import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <h1>Cookie Policy</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>1. Introduction</h2>
      <p>
        CapitolGains.io ("we", "us", or "our") uses cookies and similar tracking technologies to enhance your experience on our website. This Cookie Policy 
        explains what cookies are, how we use cookies, and your choices regarding cookies.
      </p>

      <h2>2. What Are Cookies?</h2>
      <p>
        Cookies are small text files that are placed on your device when you visit a website. They help us understand how you use our site, remember your preferences, 
        and improve your experience. Cookies can be "persistent" or "session" cookies.
      </p>

      <h2>3. How We Use Cookies</h2>
      <p>
        We use cookies for the following purposes:
      </p>
      <ul>
        <li><strong>Essential Cookies:</strong> These cookies are necessary to provide you with services available through our website and to enable you to use certain features.</li>
        <li><strong>Performance Cookies:</strong> These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.</li>
        <li><strong>Functional Cookies:</strong> These cookies allow us to remember choices you make, such as your language preference, and provide enhanced, more personalized features.</li>
        <li><strong>Advertising Cookies:</strong> These cookies are used to deliver advertisements that are relevant to you and your interests.</li>
      </ul>

      <h2>4. Third-Party Cookies</h2>
      <p>
        We may also allow third-party cookies from our advertising partners or service providers to track your browsing activities across the web to provide you with 
        targeted advertising. We do not control these cookies and recommend reviewing the privacy policies of these third parties.
      </p>

      <h2>5. Your Choices Regarding Cookies</h2>
      <p>
        You can control or delete cookies through your browser settings. However, please note that disabling cookies may affect your ability to use certain features of our website.
        For more information on how to manage cookies, visit your browser's help menu.
      </p>

      <h2>6. Changes to This Cookie Policy</h2>
      <p>
        We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website after changes are 
        made signifies your acceptance of the revised policy.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about our Cookie Policy, please contact us at [contact email or form link].
      </p>
    </div>
  );
};

export default CookiePolicy;
