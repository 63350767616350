import React from 'react';

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <h1>Disclaimer</h1>
      
      <p>Last Updated: [Date]</p>

      <h2>1. General Information</h2>
      <p>
        The information provided on CapitolGains.io is for general informational purposes only. While we strive to keep the information up to date and accurate, 
        we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability 
        with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.
      </p>

      <h2>2. No Professional or Financial Advice</h2>
      <p>
        CapitolGains.io is not intended to provide financial, legal, or investment advice. The content on our website is not a substitute for professional advice 
        and should not be relied upon as such. We are not suggesting or recommending that you make any specific investment decision, nor are we offering any advice 
        on the purchase or sale of any stock or other financial instruments in any way. You should consult with a qualified professional before making any decisions 
        based on the information provided.
      </p>

      <h2>3. No Recommendations or Endorsements</h2>
      <p>
        CapitolGains.io does not endorse or recommend any specific investments, stocks, securities, or other financial products. Any mention of specific securities or 
        other financial instruments is purely for informational purposes and does not constitute an offer to sell or a solicitation of an offer to buy any financial products.
      </p>

      <h2>4. Investment Risks</h2>
      <p>
        Investing in stocks, securities, and other financial instruments involves risk, including the potential loss of principal. Past performance is not indicative 
        of future results. You should be aware of all the risks associated with investing and seek advice from an independent financial advisor if you have any doubts.
      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        In no event will CapitolGains.io, its affiliates, or their respective officers, directors, employees, or agents be liable for any loss or damage including 
        without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of or in 
        connection with the use of this website.
      </p>

      <h2>6. External Links</h2>
      <p>
        Through this website, you may be able to link to other websites that are not under the control of CapitolGains.io. We have no control over the nature, content, 
        and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
      </p>

      <h2>7. Changes to This Disclaimer</h2>
      <p>
        We may update this Disclaimer from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website 
        after changes are made signifies your acceptance of the revised Disclaimer.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Disclaimer, please contact us at [contact email or form link].
      </p>
    </div>
  );
};

export default Disclaimer;
