// src/pages/MissionStatement.js
import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import BackgroundAnimation from '../components/BackgroundAnimation';


const AboutUs = () => {
    return (
        <Container maxWidth="md">

            <BackgroundAnimation />
            {/* New Section: What CapitolGains.io Offers */}
            <Box sx={{ padding: 8, paddingTop: 4, backgroundColor: 'rgba(245, 245, 245, 0.9)', borderRadius: 3, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', marginBottom: 4, marginTop: 4 }}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#3f51b5', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)', paddingTop: 4, textAlign: 'center' }}>
                    About Capitol Gains
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: '#333', lineHeight: 1.8, marginBottom: 4, paddingX: 2 }}>
                    CapitolGains.io offers free and easy access to comprehensive historical stock trade data of U.S. Congress members, including crucial details not available on the official House and Senate websites. Our platform provides exact stock prices at the time of purchase or sale and tracks the subsequent changes in their value, enabling the public to gain meaningful insights into stock trading activity that would otherwise be impossible. CapitolGains.io simplifies this process with intuitive insights and straightforward analytics not available anywhere else.
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: '#333', lineHeight: 1.8, marginBottom: 4, paddingX: 2 }}>
                    With CapitolGains.io, you can effortlessly monitor and analyze the trading activities of individual Congress members, detect patterns, spot unusual behaviors, and gain insights into their average returns and typical transaction amounts.
                </Typography>
                <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: '#333', lineHeight: 1.8, paddingX: 2 }}>
                    By making this information truly accessible and actionable, CapitolGains.io empowers citizens to stay informed and effectively hold their representatives accountable.
                </Typography>
            </Box>

            <Box sx={{ paddingTop: 6 }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Who We Are
                </Typography>
                <Typography variant="body1" align="center" paragraph sx={{ fontSize: '1.2rem', marginBottom: 4 }}>
                    Our purpose is to provide transparent, fact-based insights into the stock trading activities of U.S. Congress members, empowering citizens to stay informed and hold their representatives accountable.
                </Typography>

                <Divider sx={{ marginBottom: 4 }} />

                <Typography variant="h4" gutterBottom>
                    Our Values
                </Typography>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Transparency
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        We believe in full transparency. Our platform is dedicated to openly sharing data and methodologies, ensuring that all information presented is accessible and verifiable by the public.
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Non-Partisanship
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        We are committed to remaining neutral and unbiased. Our focus is solely on presenting facts without political influence, allowing users to draw their own conclusions based on accurate data.
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Accuracy
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        We prioritize the integrity and precision of the data we provide. Our team rigorously verifies all information to ensure that our users can trust the insights we offer.
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Empowerment
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        We aim to empower citizens by providing them with the tools and knowledge necessary to make informed decisions about their elected officials' actions and motivations.
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Accountability
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        By shining a light on the financial activities of Congress members, we encourage accountability and foster a culture of responsibility within our government.
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Innovation
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        We continuously seek to improve our platform, employing the latest technologies and data analysis techniques to offer the most relevant and up-to-date information.
                    </Typography>
                </Box>

                <Box sx={{ marginBottom: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Integrity
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ fontSize: '1rem' }}>
                        Our work is guided by a commitment to ethical standards and honesty in all our operations and communications.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default AboutUs;
