import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';

const DEIPage = () => {
    return (
        <Container sx={{ paddingTop: 4, paddingBottom: 4 }}>
            <Typography variant="h4" gutterBottom>
                Our Stance on Diversity, Equity, and Inclusion (DEI)
            </Typography>

            <Typography variant="body1" paragraph>
                At Capitol Gains, we firmly believe in a merit-based approach. We do not support DEI initiatives that prioritize characteristics such as race, gender, or sexuality over skills, experience, and performance. Our focus is on individual merit and achievements, ensuring that every person has an equal opportunity to succeed based on their capabilities and contributions.
            </Typography>

            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Why We Are Against DEI Initiatives
                </Typography>
                <Typography variant="body1" paragraph>
                    We believe that DEI initiatives can sometimes lead to discrimination by focusing too heavily on immutable characteristics rather than on a person's actual qualifications and potential. At Capitol Gains, we are committed to fostering an environment where success is determined by hard work, talent, and dedication. By focusing on merit, we ensure that all individuals are evaluated fairly and objectively, without any bias or preferential treatment.
                </Typography>
            </Box>

            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Our Merit-Based Approach
                </Typography>
                <Typography variant="body1" paragraph>
                    Our approach emphasizes:
                    <ul>
                        <li>Objective criteria for hiring and promotions based on skills, experience, and performance.</li>
                        <li>A culture that rewards hard work and innovation, allowing all individuals to thrive based on their contributions.</li>
                        <li>Transparent decision-making processes that focus solely on merit, ensuring fairness for all.</li>
                    </ul>
                </Typography>
            </Box>

            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Commitment to Fairness and Equality
                </Typography>
                <Typography variant="body1" paragraph>
                    We are committed to providing equal opportunities for all, based solely on merit. We believe that by not considering race, gender, or sexuality in our decisions, we uphold the true principles of fairness and equality. Everyone at Capitol Gains is encouraged to excel and achieve based on their own merits and abilities.
                </Typography>
            </Box>

            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Open Dialogue and Feedback
                </Typography>
                <Typography variant="body1" paragraph>
                    We understand that this is a complex and sometimes controversial topic. We invite open dialogue and feedback on our stance. If you have any questions, suggestions, or concerns, please reach out to us at <Link href="mailto:feedback@capitolgains.io" color="secondary">feedback@capitolgains.io</Link>. We value all perspectives and are committed to continuous improvement.
                </Typography>
            </Box>
        </Container>
    );
};

export default DEIPage;
