import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import pelosiImage from '../assets/congress_members_banner.png';

const PelosiImage = ({ dollarsEnabled }) => {
    const controls = useAnimation();

    useEffect(() => {
        const animate = async () => {
            if (dollarsEnabled) {
                await controls.start({
                    x: 0, // Move to the left edge of the screen
                    y: 0, // Move up to where the bottom of the image aligns with the bottom of the screen
                    transition: {
                        duration: 2, // Adjust the duration as needed
                        ease: "easeOut",
                    },
                });
            } else {
                await controls.start({
                    x: -200, // Move off-screen to the left
                    y: window.innerHeight, // Move off-screen to the bottom
                    transition: {
                        duration: 2, // Adjust the duration as needed
                        ease: "easeIn",
                    },
                });
            }
        };

        animate();
    }, [dollarsEnabled, controls]);

    return (
        <motion.img
            src={pelosiImage}
            alt="Nancy Pelosi"
            initial={{ x: -200, y: window.innerHeight }} // Start from bottom left, off-screen
            animate={controls}
            style={{
                position: 'fixed',
                bottom: 0, // Ensure the image aligns with the bottom of the screen
                left: 0,
                width: '350px', // Increased size
                height: 'auto',
                zIndex: 10, // Ensure it's on top of the dollar animation
            }}
        />
    );
};

export default PelosiImage;
