import React from 'react';
import dollarFront from '../assets/dollar_front.png';
import dollarBack from '../assets/dollar_back.png';

const DollarIcon = ({ isBack }) => (
    <img 
        src={isBack ? dollarBack : dollarFront}
        alt={isBack ? "Dollar Bill Back" : "Dollar Bill Front"}
        style={{ width: '200%', height: 'auto', objectFit: 'contain' }}
    />
);

export default DollarIcon;